import { render, staticRenderFns } from "./DealerOfferItem.vue?vue&type=template&id=08908db6&scoped=true"
import script from "./DealerOfferItem.vue?vue&type=script&lang=ts"
export * from "./DealerOfferItem.vue?vue&type=script&lang=ts"
import style0 from "./DealerOfferItem.vue?vue&type=style&index=0&id=08908db6&prod&lang=sass&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "08908db6",
  null
  
)

export default component.exports