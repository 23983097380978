
// Libaries
import { Component, Prop, Vue, namespace } from "nuxt-property-decorator";
import { ButtonText, PaymentTypes } from "~/operations/messages";

// Components
import RouterLink from "~/components/atoms/text/RouterLink.vue";
import SummedRating from "~/components/molecules/display/ratings/SummedRating.vue";

// Mixins
import Icon from "~/components/atoms/embedding/Icon.vue";
import OrderExtraInfo from "~/components/molecules/display/OrderExtraInfo.vue";
import { returnFormattedDate, returnPriceFormatDE } from "~/mixins/formatting";
import { getKeyByValue } from "~/operations/shared";
import { DealerData, PAYMENT_TYPES } from "~/types/Checkout";
import PaymentType from "./PaymentType.vue";

const CheckoutModule = namespace("checkout");

@Component({
  scrollToTop: true,
  components: {
    RouterLink,
    SummedRating,
    OrderExtraInfo,
    PaymentType,
    Icon,
    // load components when details are clicked
    DealerOfferDetailsOverlay: () =>
      import("~/components/organisms/display/DealerOfferDetailsOverlay.vue"),
    PriceInfoModal: () =>
      import("~/components/molecules/display/PriceInfoModal.vue"),
    CertificateModal: () =>
      import("~/components/molecules/display/CertificateModal.vue"),
  },
  methods: {
    returnPriceFormatDE,
    returnFormattedDate,
  },
})
export default class DealerOfferItemMolecule extends Vue {
  @Prop() index: number;
  @Prop() fastestDelivery: string;
  @Prop() dealerData: DealerData;
  @Prop() selectPelletsOption: string;
  @Prop() isPelletsSite: boolean;

  @Prop({ default: false }) showPaletColumn: boolean;
  @Prop() dealRequirements: {
    amount: string;
    deliveryTimes: string;
    hose: string;
    payment_type: string;
    prod: string;
    short_vehicle: string;
    unloading_points: string;
    zipcode: string;
    type?: string;
  };

  ButtonText = ButtonText;

  PaymentTypes = PaymentTypes;

  showCertificateModal = false;

  showPriceDetails = false;

  showPriceInfoModal = false;
  process = process;

  @CheckoutModule.Mutation("setValidComparsionPrice")
  setValidComparsionPrice;

  get COMPUTED_ENV_CDN_PATH() {
    return process.env.PARTNER_MEDIA_CDN;
  }

  initFallbackImage(event: Event) {
    const target = event.target as HTMLImageElement;
    if (target instanceof HTMLImageElement) {
      // eslint-disable-next-line no-undef
      target.src = require("~/assets/images/placeholders/no-logo.jpg");
    }
  }

  get totalAmount(): any {
    if (this.$route.query.amount) {
      return this.$route.query.amount;
    }
    return "3000";
  }

  getPaymentType() {
    let paymentType = this.$route.query.payment_type;
    switch (paymentType) {
      case PAYMENT_TYPES.all:
        return PaymentTypes[this.dealerData.dealer.defaultPaymentType];
      case PAYMENT_TYPES.cash:
      case PAYMENT_TYPES.invoice:
      case PAYMENT_TYPES.guaranteedPayment:
      case PAYMENT_TYPES.directDebit:
      case PAYMENT_TYPES.inAdvance:
        return PaymentTypes[paymentType];
      default:
        if (
          this.dealerData.dealer.requestedPaymentType &&
          this.dealerData.dealer.requestedPaymentType !== PAYMENT_TYPES.all
        ) {
          return PaymentTypes[this.dealerData.dealer.requestedPaymentType];
        }
        return PaymentTypes[this.dealerData.dealer.defaultPaymentType];
    }
  }

  get showOtherPaymentTypes(): boolean {
    return this.otherPaymentTypes.length > 1;
  }

  get paymentType(): string {
    return this.getPaymentType();
  }

  get bagsPerPallet(): number {
    return this.dealerData.pricing.common.bagsPerUnit || 65;
  }

  get weightPerBag(): number {
    return this.dealerData.pricing.common.unitWeight || 15;
  }

  get weightPerPallet(): number {
    return this.bagsPerPallet * this.weightPerBag;
  }

  get totalPallets(): number {
    return Math.ceil(this.totalAmount / 1000);
  }

  get formattedPalletInfo(): string {
    return `1 Palette mit ${this.weightPerPallet} kg`;
  }

  get formattedBagInfo(): string {
    return `${this.bagsPerPallet} Säcke je ${this.weightPerBag}kg`;
  }

  get weightPerPalette(): number {
    if (this.selectPelletsOption === "pallet") {
      const bagsPerUnit = this.dealerData.pricing.common.bagsPerUnit || 65;
      const unitWeight = this.dealerData.pricing.common.unitWeight || 15;
      return bagsPerUnit * unitWeight;
    }
    return 1000;
  }

  get requestedPallets(): number {
    return parseInt(String(this.$route.query.amount)) / 1000;
  }

  get totalWeight(): number {
    return this.weightPerPalette * this.requestedPallets;
  }

  get totalBrutto() {
    return returnPriceFormatDE(
      this.isPelletsSite
        ? this.dealerData?.pricing?.ton?.brutto
        : this.dealerData?.pricing?._100L?.brutto,
    );
  }

  get hasDealerRating(): boolean {
    return this.dealerData.dealer.rating.averageRating > 0;
  }

  get paymentTypeImgUrl(): string {
    return `${process.env.PARTNER_MEDIA_CDN}/img/payment-types/${this.imageUrl}.svg`;
  }

  get imageUrl(): string {
    return this.getPaymentType();
  }

  get deliveryTime(): string {
    if (this.dealerData.delivery.type === "twentyFourHours") {
      return "(in 24 Stunden)";
    } else if (this.dealerData.delivery.durationDays === 0) {
      return "(Heute)";
    } else {
      return `(in ${this.dealerData.delivery.durationDays} Tagen)`;
    }
  }

  get hasEnPlusCertification(): boolean {
    return (
      this.dealerData?.dealer?.otherQualitySteps?.includes("enPlus") || false
    );
  }

  get hasDinPlusCertification(): boolean {
    return (
      this.dealerData?.dealer?.otherQualitySteps?.includes("dinPlus") || false
    );
  }
  showDealerCertificate() {
    this.showCertificateModal = true;
  }

  closeDealerCertificationModal() {
    this.showCertificateModal = false;
  }

  sortPaymentTypes(a: string, b: string): number {
    const sortOrder = [
      "ec",
      "cash",
      "invoice",
      "guaranteedPayment",
      "directDebit",
      "inAdvance",
    ];

    const aIndex = sortOrder.indexOf(a);
    const bIndex = sortOrder.indexOf(b);

    if (aIndex === -1) {
      return 1;
    } else if (bIndex === -1) {
      return -1;
    } else {
      return aIndex - bIndex;
    }
  }

  get otherPaymentTypes(): string[] {
    const a = JSON.parse(
      JSON.stringify(this.dealerData.dealer.otherPaymentTypes),
    );
    return a.sort(this.sortPaymentTypes).map((paymentType: string) => {
      return PaymentTypes[paymentType];
    });
  }

  get paymentTypesMessage(): string {
    return this.otherPaymentTypes.length > 1
      ? `+${this.otherPaymentTypes.length - 1} Weitere Zahlarten`
      : "keine weiteren Zahlarten";
  }

  get showPelletsPriceInfo(): boolean {
    return this.$route.query.prod !== "loose" && this.isPelletsSite;
  }

  get hasValidImageSrc(): boolean {
    return !!this.dealerData.dealer.logoPath;
  }

  get imageSrc(): string {
    return this.hasValidImageSrc
      ? `${this.COMPUTED_ENV_CDN_PATH}${this.dealerData.dealer.logoPath}`
      : // eslint-disable-next-line no-undef
        require("~/assets/images/placeholders/no-logo.jpg");
  }

  togglePriceDetails() {
    this.showPriceDetails = !this.showPriceDetails;
    if (this.showPriceDetails === true) document.body.style.overflow = "hidden";
    if (this.showPriceDetails === false) document.body.style.overflow = "auto";
  }

  togglePriceInfoModal() {
    this.showPriceInfoModal = !this.showPriceInfoModal;
    if (this.showPriceInfoModal) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }

  redirectToCheckout() {
    this.setValidComparsionPrice(true);

    const baseQuery: any = {
      amount: this.dealRequirements.amount,
      businessCustomerId: this.dealerData.dealer.id,
      businessCustomerSlug: this.dealerData.dealer.slug,
      deliveryAreaId: this.dealerData.dealer.daId,
      legacyId: String(this.dealerData.dealer.legacyId ?? -1),
      deliveryTimes: this.dealRequirements.deliveryTimes,
      preselecteddeliveryTimes: this.dealRequirements.deliveryTimes,
      hose: this.dealRequirements.hose,
      payment_type: getKeyByValue(PaymentTypes, this.paymentType),
      prod: this.dealRequirements.prod,
      preselectedProd: this.dealRequirements.prod,
      short_vehicle: this.dealRequirements.short_vehicle,
      unloading_points: this.dealRequirements.unloading_points,
      zipcode: this.dealRequirements.zipcode,
      checkoutStep: "0",
      numberOfRates: "12",
    };

    if (this.isPelletsSite) {
      baseQuery.quality_steps = this.$route.query.quality_steps;
      baseQuery.dust_protection = "dustprotection-false";
      baseQuery.totalPallets = this.totalPallets;
      baseQuery.weightPerPallet = this.weightPerPallet;
      baseQuery.weightPerBag = this.weightPerBag;
      baseQuery.bagsPerPallet = this.bagsPerPallet;
      baseQuery.totalWeight = this.totalWeight;
    }

    const path = this.isPelletsSite
      ? "/pelletspreise/pellets-checkout"
      : "/bestellung/heizoel-checkout";

    this.$router.push({
      path,
      query: baseQuery,
    });
  }
}
