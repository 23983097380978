
// Libraries
import {
  Component,
  Emit,
  namespace,
  Prop,
  Vue,
  Watch,
} from "nuxt-property-decorator";
// Components
import RouterLink from "~/components/atoms/text/RouterLink.vue";

import { debounce } from "~/operations/debounce";
import {
  ButtonText,
  DeliveryTimeInfoText,
  DeliveryTimes,
  DeliveryTruck,
  DeliveryTruckLegacy,
  HoseLength,
  InputFieldLabel,
  InputPlaceholder,
  LabelAdditives,
  LegacyPayment,
  LegacyProduct,
  PaymentTypes,
  pelletsHoseLength,
  pelletsProducts,
  Products,
} from "~/operations/messages";
import { breakpoints } from "~/operations/screensizes";

const CalculatorModule = namespace("calculator");

@Component({
  components: {
    RouterLink,
    DeliveryTypeRadio: () =>
      import("~/components/molecules/display/DeliveryTypeRadio.vue"),
    DescriptionOverlay: () =>
      import("~/components/molecules/display/DescriptionOverlay.vue"),
  },
})
export default class PriceComparison extends Vue {
  @Prop({ default: "Heizölpreise" }) title: string;
  @Prop() cityName: string;
  @Prop() selectPelletsOption: string;
  @Prop({ default: false }) isPelletsSite: boolean;

  @CalculatorModule.State("currentTime")
  currentTime: any;

  deliveryTimeInfoText = DeliveryTimeInfoText;
  ButtonText = ButtonText;
  InputFieldLabel = InputFieldLabel;
  InputPlaceholder = InputPlaceholder;
  LabelAdditives = LabelAdditives;
  Products = Products;
  pelletsProducts = pelletsProducts;
  pelletsHoseLength = pelletsHoseLength;
  PaymentTypes = PaymentTypes;
  DeliveryTruck = DeliveryTruck;
  showMoreOptions = true;
  moreOptionsToggled = false;
  userZipCodeSupportMessage = "";
  userOilAmountSupportMessage = "";
  descriptionOverlays = {
    productTypeDescription: {
      visibility: false,
      items: [
        {
          title: Products.normal,
          text: `${Products.normal} ist für alle Heizungen geeignet. Es entspricht der DIN 51603-1 mit einem max. Schwefelgehalt von 0,005% und wird aus einer Raffinerie oder einem Tanklager Ihrer Region geliefert. Heizöl derselben Herkunft wird unter verschiedenen Markennamen vertrieben.`,
        },
        {
          title: Products.premium,
          text: `${Products.premium} ist durch Additive verbessertes ${Products.normal}. Es schont Tank- und Heizungsanlage, führt zu geringeren Wartungskosten und riecht gut.`,
        },
        {
          title: Products.climateNeutralNormal,
          text: `Ihre Heizölbestellung wird durch den Kauf von Klimaschutzzertifikaten klimaneutral gestellt. Nähere Informationen zu den Klimaschutzprojekten erhalten Sie von ihrem Heizölhändler.`,
        },
        {
          title: Products.climateNeutralPremium,
          text: `Mit dem Kauf von ${Products.climateNeutralPremium} leisten Sie einen aktiven Beitrag zum Umwelt- und Klimaschutz. Die Emissionen, die dennoch beim Heizen mit dem Premium-Heizöl klimaneutral entstehen, werden durch die Unterstützung zertifizierter Klimaschutzprojekte ausgeglichen. Nähere Informationen zu den Klimaschutzprojekten erhalten Sie von Ihrem Heizölhändler.`,
        },
      ],
    },
    deliveryTypeDescription: {
      visibility: false,
      items: [
        {
          title: "Ohne",
          text: "Kein Aufpreis, der Händler entscheidet über den Liefertermin innerhalb der maximalen Lieferfrist.",
        },
        {
          title: "6 - 10 Tage (Mo. - Fr.)",
          text: "Innerhalb von 6 – 10  Tagen (Montag bis Freitag)",
        },
        {
          title: "3 - 5 Tage (Mo. – Fr.)",
          text: "Innerhalb von 3 – 5  Tagen (Montag bis Freitag)",
        },
        {
          title: "Express (2 Tage, Mo. - Fr.)",
          text: "Innerhalb von 2 Tagen (Montag bis Freitag)",
        },
        {
          title: "24 Stunden",
          text: "Nur bei Bestellung von Montag bis Donnerstag zwischen 6 – 16 Uhr",
        },
        {
          title: "Heute",
          text: "Nur bei Bestellung vor 10 Uhr",
        },
        {
          title: "07:00 – 12:00 Uhr",
          text: "Lieferung zwischen 07:00 und 12:00 Uhr",
          hideInDialog: true,
        },
        {
          title: "12:00 – 17:00 Uhr",
          text: '"Lieferung zwischen 12:00 und 17:00 Uhr',
          hideInDialog: true,
        },
        {
          title: "Früh / Spät",
          text: " Lieferung zwischen 07:00 – 12:00 Uhr oder 12:00 – 17:00 Uhr. Sie bestimmen ob Vormittags oder Nachmittags geliefert werden soll. Der Händler entscheidet weiterhin über den Tag innerhalb der maximalen Lieferfrist.",
        },
      ],
    },
    paymentTypeDescription: {
      visibility: false,
      items: [
        {
          title: PaymentTypes.ec,
          text: `Sie zahlen am Tag der Lieferung sicher und bequem mit Ihrer ${PaymentTypes.ec}.`,
        },
        {
          title: PaymentTypes.cash,
          text: `Sie zahlen am Tag der Lieferung mit Bargeld am Tankwagen.`,
        },
        {
          title: PaymentTypes.guaranteedPayment,
          text: `Sie bestellen jetzt und zahlen bequem in monatlichen Raten. Die Zahlart "${PaymentTypes.guaranteedPayment}" ist nur bei einer Entladestelle und bei einem Bestellwert bis 3.000€ verfügbar.`,
        },
        {
          title: PaymentTypes.invoice,
          text: `Sie zahlen bequem nach der Lieferung per ${PaymentTypes.invoice}.`,
        },
        {
          title: PaymentTypes.directDebit,
          text: `Sie ermöglichen dem Heizölhändler, dass er den Betrag von Ihrem Konto einziehen darf.`,
        },
        {
          title: PaymentTypes.inAdvance,
          text: `Sie zahlen bequem im Voraus auf das Konto des Heizölhändlers. Minder- oder Mehrabnahme werden nach der Belieferung ausgeglichen.`,
        },
      ],
    },
    trailerTypeDescription: {
      visibility: false,
      items: [
        {
          title: `mit Anhänger möglich`,
          text: `Möglicherweise will der Heizölhändler die Lieferung mit einem Anhänger vornehmen. Bitte prüfen Sie, ob die Voraussetzungen für eine Lieferung mit Anhänger bei Ihnen gegeben sind.`,
        },
      ],
    },
    unloadingPointsDescription: {
      visibility: false,
      items: [
        {
          title: `Entladestellen`,
          text: `Eine Entladestelle entspricht einem Füllstutzen. Auch wenn die Tanks bei der Heizöl-Lieferung in direkter Nähe zueinander liegen. Bei einer Sammelbestellung müssen die Entladestellen dieselbe Postleitzahl haben und dürfen max. 3 km auseinanderliegen.`,
        },
      ],
    },
  };

  requestData: any = this.getInitialRequestData(this, this.isPellets);
  requestDataComparsion: any = this.getInitialRequestData(this, this.isPellets);

  get unloading_pointsOptions(): Array<{ name: string; value: number }> {
    return [
      { name: `1`, value: 1 },
      { name: `2`, value: 2 },
      { name: `3`, value: 3 },
      { name: `4`, value: 4 },
      { name: `5`, value: 5 },
      { name: `6`, value: 6 },
      { name: `7`, value: 7 },
      { name: `8`, value: 8 },
      { name: `9`, value: 9 },
    ];
  }

  hoseLengthOptions: Array<{ name: string; value: string }> = [
    { name: `${DeliveryTruck.hoseLength1}`, value: `fortyMetre` },
    { name: `${DeliveryTruck.hoseLength2}`, value: `sixtyMetre` },
    { name: `${DeliveryTruck.hoseLength3}`, value: `eightyMetre` },
  ];

  vehicleTrailerOptions: Array<{ name: string; value: string }> = [
    { name: `${DeliveryTruck.withTrailer}`, value: `withTrailer` },
    { name: `${DeliveryTruck.withoutTrailer}`, value: `withoutTrailer` },
  ];

  vehicleTrailerPelletsOptions: Array<{ name: string; value: string }> = [
    { name: `${DeliveryTruck.withTrailer}`, value: `withTrailer` },
    { name: `${DeliveryTruck.withoutTrailer}`, value: `withoutTrailer` },
    { name: `${DeliveryTruck.smallTruck}`, value: "smallTruck" },
  ];

  productOptions: Array<{ name: string; value: string }> = [
    { name: `${Products.normal}`, value: `normal` },
    { name: `${Products.premium}`, value: `premium` },
    {
      name: `${Products.climateNeutralPremium}`,
      value: `climateNeutralPremium`,
    },
    { name: `${Products.climateNeutralNormal}`, value: `climateNeutralNormal` },
    { name: `${Products.bio10}`, value: `bio10` },
    { name: `${Products.bio15}`, value: `bio15` },
    { name: `${Products.bio10Premium}`, value: `bio10Premium` },
  ];

  selectPaymentOptions: Array<{ name: string; value: string }> = [
    { name: PaymentTypes.ec, value: `ec` },
    { name: PaymentTypes.cash, value: `cash` },
    { name: PaymentTypes.guaranteedPayment, value: `guaranteedPayment` },
    { name: PaymentTypes.invoice, value: `invoice` },
    { name: PaymentTypes.directDebit, value: `directDebit` },
    { name: PaymentTypes.inAdvance, value: `inAdvance` },
    { name: PaymentTypes.all, value: `all` },
  ];
  selectDeliveryDateOptions = DeliveryTimes;

  loadAvailabilityOptions = true;

  nextButtonAnimated = false;

  @CalculatorModule.Action("fetchAvailableOptions")
  _fetchAvailableOptions;

  @CalculatorModule.State("availabilities")
  availabilities;

  async created() {
    this.redirectIfLegacy();

    if (this.requestData.zipcode && !process.server) {
      await this.fetchAvailableOptions();
      //
    }
  }

  mounted() {
    this.showMoreOptions = window.innerWidth > breakpoints.lg;
  }

  @Watch("$store.state.federalstates.cityRequestData.zipcode")
  getZipcode() {
    const zipcode =
      this.$route.query.zipcode ??
      this.$store.state.federalstates.cityRequestData.zipcode;
    this.requestData.zipcode = zipcode;
    this.requestDataComparsion.zipcode = zipcode;

    if (this.loadAvailabilityOptions) {
      this.fetchAvailableOptions();
    }
    this.loadAvailabilityOptions = false;
  }

  @Watch("requestData", { deep: true })
  onRequestDataChange() {
    this.emitSyncedDataChange();
  }

  @Watch("selectPelletsOption")
  async onPelletsOptionChange(newValue: string) {
    if (newValue === "pallet" || newValue === "bigbag") {
      this.requestData.amount = 1;
    } else if (newValue === "loose") {
      this.requestData.amount = 6000;
    } else {
      this.requestData.amount = 3000;
    }

    this.userOilAmountSupportMessage = "";
    await this.fetchAvailableOptions({ newPelletsType: newValue });
  }

  dropInvalidDeliveryTimes(availableDeliverTimesOptions: Array<string>) {
    const originList = DeliveryTimes.map((ele) => ele.value);
    let newList = [];
    originList.forEach((item) => {
      if (availableDeliverTimesOptions.includes(item)) {
        newList.push(...DeliveryTimes.filter((ele) => ele.value === item));
      }
    });
    this.selectDeliveryDateOptions = this.prepareRequestParamsAndSelects(
      availableDeliverTimesOptions,
      "deliveryTimes",
      newList,
      DeliveryTimes,
    );
  }

  dropInvalidPaymentOptions(availablePaymentOptions: Array<string>) {
    const originList = Object.keys(PaymentTypes);
    let newList = [];
    originList.forEach((item) => {
      if (availablePaymentOptions.includes(item)) {
        newList.push({ name: PaymentTypes[item], value: item });
      }
    });

    this.selectPaymentOptions = this.prepareRequestParamsAndSelects(
      availablePaymentOptions,
      "payment_type",
      newList,
      PaymentTypes,
    );
  }

  get isCurrentPelletsSite(): boolean {
    return this.$route.path.includes("pelletspreise");
  }

  get debouncedWatchZipCodeInput() {
    return debounce(this.watchZipCodeInput, 300);
  }

  get debouncedWatchAmountInput() {
    return debounce(this.watchAmountInput, 300);
  }

  get placeHolderForAmount(): number {
    if (this.selectPelletsOption === "pallet") {
      return 1;
    } else {
      return InputPlaceholder.amount;
    }
  }

  get deliveryVehiclesOptions() {
    if (this.isPelletsSite) {
      return this.vehicleTrailerPelletsOptions;
    }
    return this.vehicleTrailerOptions;
  }

  get labelName() {
    if (this.isPelletsSite) return InputFieldLabel.productQuality;
    return InputFieldLabel.type;
  }

  get showAmountInput(): boolean {
    return this.selectPelletsOption === "loose" || !this.isPelletsSite;
  }

  get isPellets() {
    return this.isPelletsSite;
  }

  get amountLabel() {
    if (this.selectPelletsOption === "pallet") {
      return this.InputFieldLabel.amountPalet;
    } else if (this.selectPelletsOption === "bigbag") {
      return this.InputFieldLabel.amountBigbag;
    }
    if (this.isPelletsSite) {
      return this.InputFieldLabel.amountKilogram;
    }

    return this.InputFieldLabel.amountLitres;
  }

  get amountIcon(): string {
    if (this.selectPelletsOption === "pallet") {
      return "icon-amount-pellets-palet.svg";
    } else if (this.selectPelletsOption === "bigbag") {
      return "icon-amount-pellets-bigbag.svg";
    } else if (this.selectPelletsOption === "loose") {
      return "icon-amount-pellets-default.svg";
    }
    return "tint-solid.svg";
  }

  dropInvalidProductOptions(availableProductOptions: Array<string>) {
    const products = this.isPelletsSite ? pelletsProducts : Products;
    const originList = Object.keys(products);

    let newList = [];
    originList.forEach((item) => {
      if (availableProductOptions.includes(item)) {
        newList.push({ name: products[item], value: item });
      }
    });

    // For now, The api does not yet return "Alle Qualitäten"
    if (this.isPelletsSite) {
      newList.unshift({
        name: "Alle Qualitäten",
        value: "isoNorm, enPlus, dinPlus",
      });
    }

    this.productOptions = this.prepareRequestParamsAndSelects(
      availableProductOptions,
      "prod",
      newList,
      products,
    );
  }

  dropInvalidHoseLengthstOptions(availableProductOptions: Array<string>) {
    if (this.isPelletsSite) {
      const products = this.pelletsHoseLength;
      const originList = Object.keys(products);
      let newList = [];
      originList.forEach((item) => {
        if (availableProductOptions.includes(item)) {
          newList.push({ name: products[item], value: item });
        }
      });

      this.hoseLengthOptions = this.prepareRequestParamsAndSelects(
        availableProductOptions,
        "hose",
        newList,
        products,
      );
    }
  }
  /**
   * Damit im FireFox die Darstellung der Select-Box mit dem Wert für den
   * Preisrechner (aus der URL) übereinstimmt. Muss bei einem PLZ wechsel
   * die neue Zahlart (wenn bei der neuen PLZ die vorher gewünschte Zahlart nicht
   * angeboten wird) in der URL eingetragen werden oder die ausgewählte Zahlart
   * an die erste Stelle der Select-Box geschoben werden.
   */
  prepareRequestParamsAndSelects(
    availableOptions,
    requestDataOption,
    newList,
    origin,
  ) {
    if (
      availableOptions.includes(String(this.requestData[requestDataOption]))
    ) {
      // Ausgwählte Option gibt es auf der neuen PLZ also muss diese an die erste
      // Stelle der Liste (Selects)
      newList.unshift({
        name: origin[String(this.requestData[requestDataOption])],
        value: this.requestData[requestDataOption],
      });
    } else {
      // Aktuelle Option gibt es auf der neuen PLZ nicht mehr. Also eine andere nehmen.
      this.requestData[requestDataOption] = newList[0].value;
    }

    // remove duplicates
    return [...new Map(newList.map((ele) => [ele.value, ele])).values()] as any;
  }

  async fetchAvailableOptions(options?: { newPelletsType?: string }) {
    if (!this.requestData.zipcode || !this.requestData.amount) return;

    try {
      const amount = options?.newPelletsType
        ? this.calculatePelletsAmount(
            this.requestData.amount,
            options.newPelletsType,
          )
        : this.$route.query.prod &&
          this.isCurrentPelletsSite &&
          this.$route.query.prod !== "loose"
        ? this.requestData.amount * 1000
        : this.requestData.amount;

      await this._fetchAvailableOptions({
        isPellets: this.isCurrentPelletsSite,
        zipcode: this.requestData.zipcode,
        type: options?.newPelletsType || this.requestData.type,
        amount: amount,
      });

      this.handleAvailabilities();
    } catch (error) {
      console.error(error);
    }
  }

  async watchZipCodeInput(value: string) {
    this.requestData.zipcode = value;

    requestAnimationFrame(async () => {
      const zipcode = this.requestData.zipcode;
      if (zipcode.length > 5)
        this.userZipCodeSupportMessage = "Postleitzahl ist zu lang";
      if (zipcode.length <= 5) this.userZipCodeSupportMessage = "";
      if (
        zipcode.length === 5 &&
        zipcode !== this.requestDataComparsion.zipcode
      )
        if (
          zipcode.length === 5 &&
          zipcode !== this.requestDataComparsion.zipcode
        ) {
          await this.fetchAvailableOptions({
            newPelletsType: this.selectPelletsOption,
          });
        }
    });
  }

  async watchAmountInput(value: string) {
    const LIMITS = {
      oil: { max: 32000, min: 500, unit: "Liter" },
      loosePellets: { max: 24000, min: 1000, unit: "kg" },
      packedPellets: { max: 24, min: 1, unit: "" },
    };

    this.requestData.amount = parseInt(value);

    const getLimit = () => {
      if (!this.isPelletsSite) {
        return LIMITS.oil;
      }
      return this.selectPelletsOption === "loose"
        ? LIMITS.loosePellets
        : LIMITS.packedPellets;
    };

    const limit = getLimit();

    requestAnimationFrame(() => {
      if (this.requestData.amount > limit.max) {
        this.userOilAmountSupportMessage =
          `Maximum ${limit.max.toLocaleString()} ${limit.unit}`.trim();
      } else if (this.requestData.amount < limit.min) {
        this.userOilAmountSupportMessage =
          `Minimum ${limit.min.toLocaleString()} ${limit.unit}`.trim();
      } else {
        this.userOilAmountSupportMessage = "";
      }
    });
  }

  handleAvailabilities() {
    if (this.isCurrentPelletsSite) {
      this.dropInvalidProductOptions(
        this.availabilities.qualitySteps ?? ["normal"],
      );
    } else {
      this.dropInvalidProductOptions(
        this.availabilities.products ?? ["normal"],
      );
    }

    this.dropInvalidDeliveryTimes(
      this.availabilities.deliveryTimes ?? ["normal"],
    );
    this.dropInvalidPaymentOptions(this.availabilities.paymentTypes ?? ["all"]);
    this.dropInvalidHoseLengthstOptions(
      this.availabilities.hoseLengths ?? ["fortyMetre"],
    );
  }

  // Helper method to calculate the correct amount
  private calculatePelletsAmount(amount: number, type: string): number {
    if (type === "pallet" || type === "bigbag") {
      return amount * 1000;
    }
    return amount;
  }

  getInitialRequestData(vm, includePelletsFields = false) {
    const baseData = {
      zipcode:
        vm.$route.query.zipcode ??
        vm.$store.state.federalstates.cityRequestData.zipcode,
      amount: Number(vm.$route.query.amount ?? 3000),
      unloading_points: Number(vm.$route.query.unloading_points ?? 1),
      payment_type: vm.$route.query.payment_type ?? "all",
      prod: vm.$route.query.prod ?? "normal",
      hose: vm.$route.query.hose ?? "fortyMetre",
      short_vehicle: vm.$route.query.short_vehicle ?? "withTrailer",
      deliveryTimes: vm.$route.query.deliveryTimes ?? "normal",
    };

    if (includePelletsFields) {
      return {
        ...baseData,
        prod: vm.$route.query.quality_steps ?? "loose",
        amount:
          vm.$route.query.prod !== "loose"
            ? Number(vm.$route.query.amount / 1000)
            : Number(vm.$route.query.amount),
      };
    }

    return baseData;
  }

  getComparisonFields(includePelletsFields = false) {
    const baseFields = [
      "zipcode",
      "amount",
      "unloading_points",
      "payment_type",
      "prod",
      "short_vehicle",
      "hose",
      "deliveryTimes",
    ];

    if (includePelletsFields) {
      return [...baseFields];
    }

    return baseFields;
  }

  toggleMoreOptions() {
    this.showMoreOptions = !this.showMoreOptions;
  }

  @Emit()
  updatePelletsValue(value: string): string {
    return value;
  }

  animatedCalcButtonTimeout = null;
  animateCalcButton() {
    this.nextButtonAnimated = true;
    this.animatedCalcButtonTimeout = setTimeout(() => {
      // TODO: possible memory leak
      this.nextButtonAnimated = false;
    }, 1000);
  }
  async fetchCityDate(zipcode) {
    await this.$store.dispatch("federalstates/fetchCityName", { zipcode });
  }

  async validateAmount(amount: number) {
    if (!this.isPelletsSite) {
      if (amount <= 32000 && amount >= 500) return false;
      else {
        this.userOilAmountSupportMessage =
          amount < 500 ? "Minimum 500 Liter" : "Maximum 32.000 Liter";
        return true;
      }
    }
  }

  async validateZipCode(zipcode: string) {
    if (zipcode.length === 5) return false;
    else {
      this.userZipCodeSupportMessage =
        zipcode.length > 5
          ? "Postleitzahl ist zu lang"
          : "Postleitzahl ist zu kurz";
      return true;
    }
  }

  yieldToMain() {
    return new Promise((resolve) => {
      setTimeout(resolve, 0);
    });
  }

  async validateAndStoreUserInput() {
    setTimeout(async () => {
      const tasks = [
        () => this.validateAmount(this.requestData.amount),
        () => this.validateZipCode(this.requestData.zipcode),
        () => this.emitAndStoreFormData(),
      ];

      let shouldContinue = true;
      let deadline = performance.now() + 50;

      for (const task of tasks) {
        if (!shouldContinue) break;

        while (
          (navigator as any).scheduling?.isInputPending() ||
          performance.now() >= deadline
        ) {
          await this.yieldToMain();
        }

        const error = await task();
        if (error) {
          shouldContinue = false;
        } else {
          deadline = performance.now() + 50;
          await this.yieldToMain();
        }
      }
      await this.fetchCityDate(this.requestData.zipcode);
    }, 0);
  }

  calculateAmount(): string {
    const shouldMultiplyByThousand = ["pallet", "bigbag"].includes(
      this.selectPelletsOption,
    );
    return shouldMultiplyByThousand
      ? (this.requestData.amount * 1000).toString()
      : this.requestData.amount.toString();
  }

  createQueryParams() {
    const baseParams = {
      zipcode: this.requestData.zipcode,
      amount: this.requestData.amount.toString(),
      unloading_points: this.requestData.unloading_points.toString(),
      payment_type: this.requestData.payment_type,
      prod: this.requestData.prod,
      hose: this.requestData.hose,
      short_vehicle: this.requestData.short_vehicle,
      deliveryTimes: this.requestData.deliveryTimes,
      type: this.selectPelletsOption,
    };

    if (this.isPelletsSite) {
      return {
        ...baseParams,
        prod: this.selectPelletsOption,
        quality_steps: this.requestData.prod,
        amount: this.calculateAmount(),
        type: this.selectPelletsOption,
      };
    }

    return baseParams;
  }

  @Emit()
  async emitAndStoreFormData() {
    this.equalizeDataComparisonObject();

    const queryParams = this.createQueryParams();

    const storageKey = this.isPelletsSite
      ? "pellets-filter-params"
      : "oil-filter-params";
    localStorage.setItem(storageKey, JSON.stringify(queryParams));

    await this.$router.push({ query: queryParams });
    this.emitSyncedDataChange();
    return this.requestData;
  }

  equalizeDataComparisonObject() {
    const comparisonFields = this.getComparisonFields(this.isPelletsSite);

    for (const field of comparisonFields) {
      this.requestDataComparsion[field] = this.requestData[field];
    }
  }

  @Emit()
  emitSyncedDataChange() {
    this.animateCalcButton();
    const comparisonFields = [
      "zipcode",
      "amount",
      "unloading_points",
      "payment_type",
      "prod",
      "short_vehicle",
      "hose",
      "deliveryTimes",
    ];

    for (const field of comparisonFields) {
      if (this.requestDataComparsion[field] !== this.requestData[field]) {
        return false;
      }
    }

    return true;
  }

  async redirectIfLegacy() {
    if (this.$route.query["pr-zip"]) {
      this.$nuxt.context.redirect(301, {
        path: "/bestellung",
        query: {
          zipcode: this.$route.query["pr-zip"],
          amount: this.$route.query["pr-amount"],
          unloading_points: this.$route.query["pr-unloading-points"],
          payment_type:
            LegacyPayment[this.$route.query["pr-payment-type"] as any],
          prod: LegacyProduct[this.$route.query["pr-product"] as any],
          hose:
            HoseLength[this.$route.query["pr-hose-length"] as any] ??
            "fortyMetre",
          short_vehicle:
            DeliveryTruckLegacy[this.$route.query["pr-truck-type"] as any] ??
            "withTrailer",
          deliveryTimes: "normal",
        },
      });
    }
    // old legacy params
    if (this.$route.query["calc[zipcode]"]) {
      this.$nuxt.context.redirect(301, {
        path: "/bestellung",
        query: {
          zipcode: this.$route.query["calc[zipcode]"],
          amount: this.$route.query["calc[amount]"],
          unloading_points: this.$route.query["calc[unloading_points]"],
          payment_type:
            LegacyPayment[this.$route.query["calc[payment_type]"] as any],
          prod: LegacyProduct[this.$route.query["calc[prod]"] as any],
          hose:
            HoseLength[this.$route.query["calc[hose]"] as any] ?? "fortyMetre",
          short_vehicle:
            DeliveryTruckLegacy[
              this.$route.query["calc[short_vehicle]"] as any
            ] ?? "withTrailer",
          deliveryTimes: "normal",
        },
      });
    }
  }

  mobileClicker(text) {
    const ref = this.$refs["hideable-container"] as any;

    this.moreOptionsToggled = !this.moreOptionsToggled;

    if (text === "mehr") {
      ref.classList.value = "price-comparison-form-organism__row";
    } else {
      ref.classList.value =
        "price-comparison-form-organism__row initial-hide-on-mobile";
    }
  }
}
