
// Libraries
import { Component, Prop, Vue } from "nuxt-property-decorator";
import { BreadCrumbDetail } from "~/types/BreadCrumb";

// Components

@Component({
  components: {},
})
export default class BreadCrumbComponent extends Vue {
  @Prop() breadCrumbData: BreadCrumbDetail[];
  @Prop({ default: false }) newsSite: boolean;
  @Prop({ default: false }) singleBlogPost: boolean;
  @Prop({ default: "" }) suffix: string;
  @Prop({ default: "Heizölpreise" }) prefix: string;

  shouldShowLandkreis(crumb: BreadCrumbDetail, index: number): boolean {
    return index === 2 && !crumb.name.includes("Kreisfreie Stadt");
  }
}
