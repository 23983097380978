
// Libraries
import { Component, Prop, Vue } from "nuxt-property-decorator";

@Component({
  components: {},
})
export default class PaymentType extends Vue {
  @Prop({ default: "-" }) imageUrl: string;
  @Prop({ default: "-" }) paymentType: string;
  @Prop({ default: false }) showPaletColumn: boolean;
  @Prop({ default: "-" }) withdrawalFileUrl: string;
  @Prop() showOtherPaymentTypes: boolean;
  @Prop() otherPaymentTypes: Array<string>;
  @Prop({ default: "" }) partnerLegalName: string;
  @Prop({ default: "" }) imprint: string;
  @Prop({ default: "" }) vatId: string;

  get paymentTypeImageUrl() {
    return `${process.env.PARTNER_MEDIA_CDN}/img/payment-types/${this.imageUrl}.svg`;
  }
}
