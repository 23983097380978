import { PaymentTypes } from "~/operations/messages";

export const errorCodeMessages = {
  E1000: `Keine Ergebnisse, bitte passen Sie Ihre Wünsche an.`,
  E1011: `Akuell beliefert kein Händler mit Ihren Wünschen diese Postleitzahl.`,
  E1021: `Die Menge darf 500 Liter pro Entladestelle nicht unterschreiten.`,
  E1022: `Die Menge muss zwischen 500 und 32.000 Liter liegen.`,
  E1031: `Diese Postleitzahl existiert nicht.`,
  E1032: `Diese Postleitzahl wird leider nicht beliefert.`,
  E1033: `Heute Bestellung steht nur Mo. - Fr. bis 10 Uhr zur Verfügung.`,
  E1034: `24 Stunden Bestellung steht nur Mo. - Fr. zwischen 6 Uhr und 16 Uhr zur Verfügung.`,
  E1040: `Die Zahlart Ratenkauf steht momentan nicht zur Verfügung.`,
  E1041: `Bei Ratenzahlung darf der Wert der Bestellung nicht über 3000 Euro liegen.`,
  E1042: `Der ${PaymentTypes.guaranteedPayment} ist bei mehr als einer Entladestelle nicht möglich.`,
  E1043: `Der Kauf auf ${PaymentTypes.guaranteedPayment} steht für diese Lieferzeit leider nicht zur Verfügung.`,
  E1044: `Der Kauf auf ${PaymentTypes.invoice} steht für diese Lieferzeit leider nicht zur Verfügung.`,
  E1045: `Der Kauf auf ${PaymentTypes.directDebit} steht für diese Lieferzeit leider nicht zur Verfügung.`,
  E1046: `Der Kauf auf ${PaymentTypes.inAdvance} steht für diese Lieferzeit leider nicht zur Verfügung.`,
  E1047: `Eine verkürzte Lieferzeit ist für Biobrennstoffe leider nicht möglich.`,
  E1051: `Diese Lieferterminoption wird nicht angeboten.`,
  E1052: `Dieser Tankwagentyp wird nicht angeboten.`,
  E1053: `Diese Schlauchlänge wird nicht angeboten.`,
  E1054: `Diese Zahlart wird nicht angeboten.`,
  E1055: `Dieses Produkt wird nicht angeboten.`,
  E1056: `Diese Produktqualität wird nicht angeboten.`,
  E1071: `Sie können maximal mit 9 Entladestellen bei uns bestellen.`,
};

export const nestJSErrorMessages = {
  "amount must not be less than 500": "E1022",
  "amount must not be greater than 32000": "E1022",
  "zipcode must be a postal code": "E1031",
  "unloading_points must not be greater than 9": "E1071",
};

export const translateErrorMessage = (errorMessage) => {
  // error codes die direkt von NestJS kommen. Bei diesen Fällen kommt
  // wird der Preisrechner nicht angefragt, um überhaupt einen Fehler anzuzeigen
  if (typeof errorMessage === "object" && errorMessage.statusCode == 400) {
    errorMessage = nestJSErrorMessages[errorMessage.message[0]];
  }

  for (const [key, value] of Object.entries(errorCodeMessages)) {
    if (errorMessage === key) {
      return value;
    }
  }

  return "Ein unbekannter Fehler ist aufgetreten";
};
