export type UserOfferRequest = {
  zipcode: string;
  amount: number;
  unloading_points: number;
  payment_type: string;
  prod: string;
  hose: string;
  short_vehicle: string;
  deliveryTimes: string;
  bcid: string;
  numberOfRates?: number;
  dust_protection?: false;
  quality_steps?: [];
};

export type UserOfferPriceRequest = {
  zipcode: string;
  amount: number;
  unloading_points: number;
  payment_type: string;
  prod: string;
  hose: string;
  short_vehicle: string;
  deliveryTimes: string;
  bcid: string;
  price: number;
};

export type PreselectionDataKeys = {
  deliveryTime: string;
  product: string;
  amount: string;
  dust_protection: boolean;
};

export type PartnerOrderPriceResponseData = {
  product: {
    normal: {
      label: string;
      price: number;
    };
    premium: {
      label: string;
      price: number;
    };
    climateNeutralNormal: {
      label: string;
      price: number;
    };
    climateNeutralPremium: {
      label: string;
      price: number;
    };
    bio10: {
      label: string;
      price: number;
    };
    bio10Premium: {
      label: string;
      price: number;
    };
    bio15: {
      label: string;
      price: number;
    };
  };
  deliveryTime: {
    fiveTenDays: {
      label: string;
      price: number;
    };
    threeFiveDays: {
      label: string;
      price: number;
    };
    today: {
      label: string;
      price: number;
    };
    twentyFourHours: {
      label: string;
      price: number;
    };
    twoWorkingDays: {
      label: string;
      price: number;
    };
    weekTimeOne: {
      label: string;
      price: number;
    };
    weekTimeTwo: {
      label: string;
      price: number;
    };
  };
  dustprotection: boolean;
};

export type UnloadingPointObject = {
  prename: string;
  lastname: string;
  street: string;
  streetNumber: string;
  phone: string;
  dynamic: true;
  amount: string;
};

export type RatepayData = {
  amount: number;
  annualPercentageRate: number;
  descriptor: string;
  interestAmount: number;
  interestRate: number;
  lastRate: number;
  monthlyDebitInterest: number;
  numberOfRates: number;
  numberOfRatesFull: number;
  paymentFirstday: number;
  rate: number;
  serviceCharge: number;
  totalAmount: number;
  transactionId: string;
};

export type UnloadingPointFormArray = Array<{
  element: string;
  variant: string;
  type: string;
  assetsIconFile: string;
  value: string;
  supportMessage: string;
  id: string;
  label: string;
  placeholder: string;
  emitTarget: string;
}>;

export enum DisabledDeliveryTimesForAddedValues {
  "today" = "today",
  "twoWorkingDays" = "twoWorkingDays",
  "twentyFourHours" = "twentyFourHours",
}

export type DealerData = {
  pricing: {
    common: {
      isDeliveryChargeActive: boolean;
      isDeliveryChargeIncluded: boolean;
      deliveryChargeName: string;
      deliveryChargePrice: number;
      bagsPerUnit?: number;
      unitWeight?: number;
      enPlusCert?: number | string;
      dinPlusCert?: number | string;
    };
    ton: {
      netto: number;
      nettoScoped: number;
      deliveryCharge: number;
      vatMonetaryScoped: number;
      vatMonetary: number;
      brutto: number;
      bruttoScoped: number;
    };
    _100L: {
      netto: number;
      deliveryCharge: number;
      vatMonetary: number;
      brutto: number;
    };
    total: {
      netto: number;
      deliveryCharge: number;
      vatMonetary: number;
      brutto: number;
      bruttoScoped?: number;
      nettoScoped?: number;
      vatMonetaryScoped?: number;
    };
    bestPriceDiff: number;
  };
  delivery: {
    date: string;
    durationDays: number;
    type: string;
  };
  fastestDelivery: boolean;
  bestPrice: boolean;
  dealer: {
    id: string;
    slug: string;
    daId: string;
    legacyId: number;
    name: string;
    logoPath: string;
    logoAltText: string;
    profileURL: string;
    defaultPaymentType: string;
    requestedPaymentType: string;
    otherPaymentTypes: string[];
    otherQualitySteps: string[];
    rating: {
      value: number;
      votes: number;
      averageRating: number;
    };
    isRal: boolean;
    isTkwCheck: boolean;
  };
};

export const PAYMENT_TYPES = {
  all: "all",
  cash: "cash",
  invoice: "invoice",
  guaranteedPayment: "guaranteedPayment",
  directDebit: "directDebit",
  inAdvance: "inAdvance",
};
