
// Libraries
import Tooltip from "@/components/atoms/Tooltip.vue";
import { Component, Emit, Prop, Vue } from "nuxt-property-decorator";

type sortOptionsTypeArray = Array<{
  name: string;
  value: string;
  direction: "asc" | "desc";
}>;

type sortOptionsTypeObject = {
  name: string;
  value: string;
  direction: "asc" | "desc";
};

@Component({ components: { Tooltip } })
export default class SortHeaderOrganism extends Vue {
  @Prop({ default: true }) showCounter: number;
  @Prop({ default: "default" }) variant: string;
  @Prop({ default: "Anzahl" }) counterLabel: number;
  @Prop({ default: 0 }) counterValue: number;
  @Prop() sortOptions: sortOptionsTypeArray;

  selectedOption: {
    value: string;
    direction: "asc" | "desc";
  } = {
    value: "",
    direction: "desc",
  };

  getColumnClasses(option: sortOptionsTypeObject, i: number) {
    const baseClasses = this.getClass(option, i);

    if (this.variant === "pellets-table") {
      return {
        ...baseClasses,
        "pellet-sort-item": true,
        "pellet-price": i === 0,
        "pellet-pallet": i === 1,
        "pellet-deliverytime": i === 2,
        "pellet-paymenttype": i === 3,
        "pellet-review": i === 4,
      };
    }

    if (this.variant === "shop-table") {
      return {
        ...baseClasses,
        "shop-sort-item": true,
        "shop-price": i === 0,
        "shop-pallet": i === 1,
        "shop-deliverytime": i === 2,
        "shop-review": i === 3,
      };
    }

    return baseClasses;
  }

  mounted() {
    this.selectedOption.value = this.sortOptions[0].value;
  }

  tooltipVisible = false;
  showToolTip() {
    this.tooltipVisible = true;
  }
  hideToolTip() {
    this.tooltipVisible = false;
  }

  setSelectedOption(value: string, direction: "asc" | "desc", index: number) {
    if (this.sortOptions[index].value === this.selectedOption.value) {
      if (direction === "asc") this.sortOptions[index].direction = "desc";
      if (direction === "desc") this.sortOptions[index].direction = "asc";
    }
    this.selectedOption.value = value;
    this.emitSelectedValue(
      this.selectedOption.value,
      this.sortOptions[index].direction,
    );
  }

  getClass(option: sortOptionsTypeObject, index: number) {
    return {
      "is--selected-true": option.value === this.selectedOption.value,
      "is--selected-false": option.value !== this.selectedOption.value,
      "sort-item": true,
      "second-item": index === 1,
    };
  }

  getIconClass(option: sortOptionsTypeObject) {
    return {
      "is--showing-sortDesc-true":
        this.selectedOption.direction === option.direction,
      "is--showing-sortDesc-false":
        this.selectedOption.direction !== option.direction,
    };
  }

  @Emit()
  emitSelectedValue(value: string, direction: "asc" | "desc") {
    return { sortByParameter: value, direction: direction };
  }
}
